.root {
    background-color: #EFF5F8;
    min-height: 100vh;
}

.formContainer {
    width: 400px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    min-height: 280px;

}