.root {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.title {
  font-size: 4em;
  color: #444;
}

h2,
h4 {
  color: #444;
}